import { AddressValueProps } from "./AddressValue";
import { UIDocument } from "@/types";
import PDFThumbnail from "./PDFThumbnail";
import ValueContainer from "./ValueContainer";
import { format } from "date-fns";

type IncorporationDocumentProps = {
  level: "success" | "failure" | "normal" | undefined;
  address: AddressValueProps;
  businessActivity: string;
  companyName: string;
  incorporationDate: string;
  companyNumber?: string | undefined;
  incorporationDocuments: UIDocument[];
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
};

const IncorporationDocument = ({
  address = {
    street1: null,
    street2: null,
    city: null,
    state: null,
    countryCode: null,
    postalCode: null,
  },
  businessActivity,
  companyName,
  incorporationDate,
  incorporationDocuments,
  level,
  companyNumber,
  handleDocumentValidationClick,
  checkResult,
}: IncorporationDocumentProps) => {
  if (!incorporationDocuments?.length) return null;

  const { street1, street2, city, state, countryCode, postalCode } = address;
  const addressLine1 = `${street1 ?? ""}${street2 ? `, ${street2}` : ""}`;
  const addressLine2 = `${city ?? ""}${state ? `, ${state}` : ""}${countryCode ? ` ${countryCode}` : ""}${
    postalCode ? ` ${postalCode}` : ""
  }`;

  const document = incorporationDocuments[0];

  return (
    <div className="grid grid-cols-2 gap-4 text-xs w-[40rem]">
      <div className="flex flex-col gap-y-2">
        <ValueContainer label="Valid Document" value={document?.isValidDocument ? "Yes" : "No"} />
        <ValueContainer label="Registered Business Name" value={companyName} />
        {(addressLine1 || addressLine2) && (
          <ValueContainer
            label="Address of Incorporation"
            value={
              <>
                <p>{addressLine1}</p>
                <p>{addressLine2}</p>
              </>
            }
          />
        )}
        {incorporationDate && (
          <ValueContainer
            label="Incorporation Date"
            value={format(new Date(incorporationDate.replaceAll("-", "/")), "MMMM d, yyyy")}
          />
        )}
        {companyNumber && <ValueContainer label="Registration Number" value={companyNumber} />}
        <ValueContainer label="Business Activity" value={businessActivity} />
      </div>
      <div className="flex flex-col gap-y-2">
        <PDFThumbnail
          documentURL={document.url}
          documentName={document.fileName}
          onDocumentNameClick={() => handleDocumentValidationClick?.(checkResult, document)}
        />
      </div>
      {document.summary && (
        <div className="col-span-2">
          <ValueContainer label="Summary" value={document.summary} />
        </div>
      )}
    </div>
  );
};

export default IncorporationDocument;
