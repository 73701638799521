import { UIDocument, CannabisLicenseDocument } from "@/types";
import ValueContainer from "./ValueContainer";
import PDFThumbnail from "./PDFThumbnail";
import { format } from "date-fns";

type CannabisLicenseCardProps = {
  verifiedBusinessName: string;
  verifiedLicenseNumber: string;
  documentDate: string;
  validDocuments: (UIDocument & CannabisLicenseDocument)[];
  invalidDocuments?: (UIDocument & CannabisLicenseDocument)[];
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
};

const CannabisLicenseCard = ({
  verifiedBusinessName,
  verifiedLicenseNumber,
  documentDate,
  validDocuments,
  invalidDocuments,
  handleDocumentValidationClick,
  checkResult,
}: CannabisLicenseCardProps) => {
  if (!(validDocuments?.length || invalidDocuments?.length)) return null;

  const validDocument = [...(validDocuments || []), ...(invalidDocuments || [])][0];

  return (
    <div className="grid grid-cols-2 gap-4 text-xs w-[40rem]">
      <div className="flex flex-col gap-y-2">
        <ValueContainer label="Valid Document" value={validDocuments[0]?.isValidDocument ? "Yes" : "No"} />
        <ValueContainer label="License Number" value={verifiedLicenseNumber} />
        <ValueContainer label="Business Name" value={verifiedBusinessName} />
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="h-full">
          <PDFThumbnail
            documentURL={validDocument.url}
            documentName={validDocument.fileName}
            onDocumentNameClick={() => handleDocumentValidationClick?.(checkResult, validDocument)}
          />
        </div>
        {documentDate && (
          <ValueContainer
            label="Document Date"
            value={format(new Date(documentDate.replaceAll("-", "/")), "MMMM d, yyyy")}
          />
        )}
        <ValueContainer label="Summary" value={validDocument.summary} />
      </div>
    </div>
  );
};

export default CannabisLicenseCard;
