// @ts-nocheck
import { twJoin } from "tailwind-merge";
import UserContext from "@/contexts/UserContext";
import { useParchaApi } from "@/hooks/useParchaApi";
import { Endpoint, NewJobMetadata } from "@/types";
import { Fragment, ReactNode, useContext, useEffect, useRef, useState, useLayoutEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import ParchaReport from "@/components/TailwindComponents/PublicBDDReport";
import ParchaLoadingScreen from "./V2_Home_Page/ParchaLoadingScreen";
import CheckContainer from "./V2_Home_Page/CheckContainer";
import FeedbackContext from "@/contexts/FeedbackContext";
import FullWidthTabs from "@/components/TailwindComponents/FullWidthTabs";
import FinalOutput from "@/components/TailwindComponents/FinalOutput";
import {
  AutoAwesome,
  CancelRounded,
  CheckCircleRounded,
  Close,
  CopyAllRounded,
  HourglassTopRounded,
  Info,
  MoreHoriz,
  PlagiarismRounded,
  BusinessRounded,
  PersonRounded,
  TimelapseRounded,
  CloseRounded,
  TaskRounded,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { format, formatDistanceToNowStrict } from "date-fns";
import ReviewBadge from "./TailwindComponents/ReviewBadge";
import ApproveBadge from "./TailwindComponents/ApproveBadge";
import DenyBadge from "./TailwindComponents/DenyBadge";
import ReactMarkdown from "react-markdown";
import { Drawer, Fab } from "@mui/material";
import NewAgentChatSSE from "./TailwindComponents/NewAgentChatSSE";
import {
  BUSINESS_PROFILE_CHECK_ID,
  WEB_PRESENCE_CHECK_ID,
  MCC_CHECK_ID,
  MCC_V2_CHECK_ID,
  ADDRESS_CHECK_ID,
  POLICY_CHECK_ID,
  HIGH_RISK_COUNTRY_CHECK_ID,
  HIGH_RISK_INDUSTRY_CHECK_ID,
  HIGH_RISK_INDUSTRY_V2_CHECK_ID,
  ADVERSE_MEDIA_CHECK_ID,
  SANCTIONS_WATCHLIST_CHECK_ID,
  BUSINESS_OWNERS_CHECK_ID,
  INCORPORATION_DOCUMENT_CHECK_ID,
  BUSINESS_OWNERSHIP_CHECK_ID,
  SOURCE_OF_FUNDS_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  CANNABIS_LICENSE_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  KYC_PROOF_OF_ADDRESS_CHECK_ID,
  KYC_SOURCE_OF_FUNDS_CHECK_ID,
  BANK_CHECK_VERIFICATION_CHECK_ID,
  PROOF_OF_ADDRESS_CLASSIFICATION_CHECK_ID,
} from "@/constants/vars";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DocumentValidation from "./TailwindComponents/DocumentValidation";
import WrappedValue from "./TailwindComponents/WrappedValue";
import StatusIndicator from "./TailwindComponents/StatusIndicator";
import AssociatedEntityFindings from "./V2_Home_Page/AssociatedEntityFindings";
import IDDReport from "./TailwindComponents/IDDReport";

const recommendationComponents = {
  Review: <ReviewBadge />,
  Approve: <ApproveBadge />,
  Deny: <DenyBadge />,
};

const NewJobView = () => {
  const [jobMetadata, setJobMetadata] = useState<NewJobMetadata | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingFeedbackData, setIsLoadingFeedbackData] = useState(true);

  const [isGenerateFollowUpOpen, setIsGenerateFollowUpOpen] = useState<boolean>(false);
  const [isDeleteJobDialogOpen, setIsDeleteJobDialogOpen] = useState<boolean>(false);
  const [isDeletingJob, setIsDeletingJob] = useState(false);
  const [isJobDetailsOpen, setIsJobDetailsOpen] = useState<boolean>(false);
  const [latestFeedbackData, setLatestFeedbackData] = useState(null);
  const [jobFeedbackData, setJobFeedbackData] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState("findings");
  const [infoTabValue, setInfoTabValue] = useState("inputs");
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [isTransitioningReportDownload, setIsTransitioningReportDownload] = useState(false);
  const [selectedValidationDocument, setSelectedValidationDocument] = useState<any>(null);
  const [toastState, setToastState] = useState({ show: false, status: "in progress" });
  const dialogDivRef = useRef();
  const deleteJobDivRef = useRef();
  const reportContainerRef = useRef<HTMLDivElement>(null);
  const { agentKey, jobId } = useParams();
  const parchaApi = useParchaApi();
  const userContext = useContext(UserContext);
  const agent = userContext?.agent;
  const endpoints = userContext?.endpoints;
  const endpoint = endpoints?.find((endpoint: Endpoint) => endpoint.agentKey === agentKey);
  const envTier = import.meta.env.VITE_ENV_TIER;
  const endpointUrl = endpoint?.endpointUrl;
  const job = jobMetadata?.job;
  const isFlutterwaveMCC = job?.agent_id === "kyb-mcc-flutterwave";
  const { user, hasRole } = userContext;
  const findingsEligibleChecks = [
    BUSINESS_PROFILE_CHECK_ID,
    WEB_PRESENCE_CHECK_ID,
    MCC_CHECK_ID,
    MCC_V2_CHECK_ID,
    ADDRESS_CHECK_ID,
    POLICY_CHECK_ID,
    HIGH_RISK_COUNTRY_CHECK_ID,
    HIGH_RISK_INDUSTRY_CHECK_ID,
    HIGH_RISK_INDUSTRY_V2_CHECK_ID,
    ADVERSE_MEDIA_CHECK_ID,
    SANCTIONS_WATCHLIST_CHECK_ID,
    BUSINESS_OWNERS_CHECK_ID,
  ];
  const kybAgentInstanceId = jobMetadata && "kyb" in jobMetadata ? Object.keys(jobMetadata["kyb"])[0] : null;
  const kybAgentInstance = kybAgentInstanceId && jobMetadata ? jobMetadata["kyb"][kybAgentInstanceId] : null;

  const agentType = agent?.agent_type;

  const kycAgentInstance = jobMetadata && "kyc" in jobMetadata ? jobMetadata["kyc"] : null;
  const kycAgentInstanceIds = jobMetadata && "kyc" in jobMetadata ? Object.keys(kycAgentInstance) : null;
  const entityAgentInstanceIds = jobMetadata && "entity" in jobMetadata ? Object.keys(jobMetadata["entity"]) : null;

  const finalAnswerCheckResult = kybAgentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === "finish_plan",
  );
  const finalAnswer = finalAnswerCheckResult?.status_messages.find(
    (message: { event: string }) => message.event === "final_answer",
  );

  const shouldRenderFindingsTab = findingsEligibleChecks.some(
    (checkId) =>
      kybAgentInstance?.check_results.some((checkResult: { command_id: string }) => checkResult.command_id === checkId),
  );

  const shouldRenderIndividualsTab = kycAgentInstanceIds && kycAgentInstanceIds.length > 0;
  const shouldRenderIndividualFindingsTab =
    agent?.agent_type === "kyc" && kycAgentInstanceIds && kycAgentInstanceIds.length > 0;
  const shouldRenderAssociatedEntitiesTab = entityAgentInstanceIds && entityAgentInstanceIds.length > 0;

  const fetchFeedbackData = async () => {
    if (!job || !endpoint || !endpointUrl || !job.id) return;

    return parchaApi.getFeedbackInputsForJob(endpointUrl, job.id).then((res) => {
      setJobFeedbackData(res);
      return res;
    });
  };

  useEffect(() => {
    if (!agent) return;

    const shouldGoToDocuments = localStorage.getItem("should_go_to_documents");

    if (shouldGoToDocuments) {
      setSelectedTab("documents");
      localStorage.removeItem("should_go_to_documents");
    } else if (agent.agent_type === "kyc") {
      setSelectedTab("individual_findings");
    } else if (agent.agent_type === "kyb" && shouldRenderFindingsTab) {
      setSelectedTab("findings");
    } else if (agent.agent_type === "kyb" && !shouldRenderFindingsTab) {
      setSelectedTab("business");
    }
  }, [agent, shouldRenderFindingsTab, jobId]);

  // Define tabs
  const tabs = [
    {
      name: "Findings",
      id: "findings",
      count: 0,
    },
    {
      name: "Business",
      id: "business",
      count: 0,
    },
    {
      name: "Individual Findings",
      id: "individual_findings",
      count: 0,
    },
    {
      name: "Individuals",
      id: "individuals",
      count: 0,
    },
    {
      name: "Associated Entities",
      id: "associated_entities",
      count: 0,
    },
    {
      name: "Documents",
      id: "documents",
      count: 0,
    },
  ];

  const sendFeedbackHandler = (
    commandResult: { content: { agent_instance_id: any; command_instance_id: any; command_id: any } },
    isThumbsUp: number | boolean | undefined,
    comment: string | undefined,
  ) => {
    if (!job || !job.id || !endpointUrl) return;

    parchaApi
      .sendFeedback(
        endpointUrl,
        job.id || job.job_id,
        `${commandResult.content.agent_instance_id}${commandResult.content.command_instance_id}`,
        isThumbsUp,
        undefined,
        {
          job_url: window.location.href,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: job.id,
          feedback_key: "command_result",
          agent_instance_id: commandResult.content.agent_instance_id,
          command_id: commandResult.content.command_id,
          command_instance_id: commandResult.content.command_instance_id,
          agent_key: agentKey,
          command_result: {
            ...commandResult,
          },
        },
        comment,
      )

      .then(async () => {
        await fetchFeedbackData();
      });
  };

  const hasHighRiskMCCAlert = (finalAnswerCheckResult: any): boolean => {
    // Step 1: Find the status_message with event equals to "final_answer"
    const finalAnswerMessage = finalAnswerCheckResult?.status_messages.find(
      (message: { event: string }) => message.event === "final_answer",
    );

    if (
      !finalAnswerMessage ||
      !finalAnswerMessage.content ||
      !finalAnswerMessage.content.output ||
      !finalAnswerMessage.content.output.evidence
    ) {
      return false; // If not found or missing expected properties
    }

    // Step 2: Get the content.output.evidence array
    const evidenceArray = finalAnswerMessage.content.output.evidence;
    if (!evidenceArray || evidenceArray.length === 0) {
      return false; // No evidence to check
    }

    // Step 3: Check if any evidence.command_result.output.alerts contains the high_risk_mcc_alert key
    for (const evidence of evidenceArray) {
      const alerts = evidence.command_result.output?.alerts;
      if (alerts && "high_risk_mcc_alert" in alerts) {
        return true; // Found high_risk_mcc_alert
      }
    }

    return false; // No high_risk_mcc_alert found
  };

  const getFinalOutputTitle = () => {
    if (!job) return "";

    if (!isFlutterwaveMCC) return job.recommendation;

    const isHighRisk = isFlutterwaveMCC ? hasHighRiskMCCAlert(finalAnswerCheckResult) : false;

    if (isHighRisk) {
      return "High Risk";
    } else if (job.recommendation === "unknown") {
      return "Unknown";
    }
    return job.recommendation;
  };

  const getFinalOutputLevel = () => {
    if (!job?.recommendation) return "normal";

    const isHighRisk = hasHighRiskMCCAlert(finalAnswerCheckResult);

    if (!isFlutterwaveMCC) {
      if (job?.recommendation === "Approve") return "success";
      if (job?.recommendation === "Deny") return "failure";
      if (job?.recommendation === "Review") return "caution";
      return "normal";
    }

    const inputMccCode = job.input_payload?.self_attested_data.mcc_code;
    const recommendationMccCode = job.recommendation;

    if (isHighRisk || recommendationMccCode === "unknown") {
      return "failure";
    } else if (inputMccCode && recommendationMccCode && inputMccCode === recommendationMccCode) {
      return "success";
    } else if (
      (inputMccCode && recommendationMccCode && inputMccCode !== recommendationMccCode) ||
      recommendationMccCode === "Review"
    ) {
      return "caution";
    }
    return "normal";
  };

  useEffect(() => {
    if (!jobId || !endpoint || !endpointUrl) return;

    setIsLoading(true);

    document.title = `Parcha - ${agentKey}`;

    // Create abort controller for each request
    const feedbackController = new AbortController();
    const metadataController = new AbortController();
    let pollingController: AbortController;

    const getFeedback = async () => {
      try {
        const res = await parchaApi.getLatestFinalOutputFeedback(endpointUrl, jobId, feedbackController.signal);
        setLatestFeedbackData(res);
        setIsLoadingFeedbackData(false);
      } catch (err: any) {
        if (err.name === "AbortError") return;
        console.error(err);
      }
    };

    const getMetadata = async () => {
      try {
        const res = await parchaApi.getJobMetadata(endpointUrl, jobId, metadataController.signal);
        if (res.job.id.replaceAll("-", "") !== jobId) return;
        setJobMetadata(res);
        setIsLoading(false);
      } catch (err: any) {
        if (err.name === "AbortError") return;
        console.error(err);
      }
    };

    getFeedback();
    getMetadata();

    const interval = setInterval(async () => {
      pollingController = new AbortController();

      try {
        const res = await parchaApi.getJobMetadata(endpointUrl, jobId, pollingController.signal);
        if (res.job.id.replaceAll("-", "") !== jobId) return;

        setJobMetadata(res);
        const isJobDone = ["complete", "error", "failed"].includes(res.job.status);

        if (isJobDone) {
          clearInterval(interval);
        }
      } catch (err: any) {
        if (err.name === "AbortError") return;
        console.error(err);
      }
    }, 2500);

    return () => {
      // Ensure all controllers are aborted on cleanup
      feedbackController.abort();
      metadataController.abort();
      if (pollingController) pollingController.abort();
      clearInterval(interval);
    };
  }, [jobId, agentKey, endpoints]);

  useEffect(() => {
    if (!jobMetadata?.job.id) return;

    document.title = `Parcha - ${jobMetadata?.job.case_id || jobMetadata?.job.input_payload?.id} - ${agentKey}`;

    fetchFeedbackData();
  }, [jobMetadata?.job?.id]);

  const renderBusinessTab = () => {
    if (!jobMetadata) return null;

    const kybAgentInstance = jobMetadata?.kyb;
    if (!kybAgentInstance) return null;

    const checkResults = Object.values(kybAgentInstance)
      .map((agentInstance: any) => agentInstance.check_results)
      .flat()
      .filter((cr) => !["run_check_finish_plan", "finish_plan"].includes(cr.command_id));

    const sectionTitle =
      checkResults?.length > 0
        ? `${
            checkResults[0].registered_business_name ||
            checkResults[0].verified_business_name ||
            checkResults[0].business_name ||
            ""
          }`
        : "";

    return (
      <div className="flex flex-col gap-y-4 ">
        {sectionTitle && <span className="font-semibold text-base">{sectionTitle}</span>}
        {checkResults.map((checkResult) => {
          return (
            <CheckContainer
              key={`${job.id}-${checkResult.command_id}-${checkResult.agent_instance_id}`}
              caseId={job.input_payload.id}
              endpointUrl={endpointUrl}
              agentRun={job}
              expanded={!checkResult.passed || isDownloadingReport}
              commandResult={checkResult}
              title={checkResult.command_name}
              auditLogMessages={checkResult.status_messages.filter((message: any) =>
                ["api_results", "command_results", "thought"].includes(message.event),
              )}
              messages={checkResult.status_messages}
              sendFeedbackHandler={sendFeedbackHandler}
              feedbackData={jobFeedbackData}
              handleDocumentValidationClick={handleDocumentValidationClick}
            />
          );
        })}
      </div>
    );
  };

  const renderIndividualsTab = () => {
    if (!jobMetadata) return null;

    const kycAgentInstance = jobMetadata?.kyc;

    if (!kycAgentInstance) return null;

    return (
      <div className="flex flex-col gap-y-4">
        {Object.values(kycAgentInstance).map((agentInstance, index) => (
          <KYCAgentInstanceSection
            agentType="kyc"
            key={`${agentInstance.agent_instance_id}-${index}`}
            agentInstance={agentInstance}
            job={job}
            endpointUrl={endpointUrl}
            isDownloadingReport={isDownloadingReport}
            handleDocumentValidationClick={handleDocumentValidationClick}
            jobFeedbackData={jobFeedbackData}
            sendFeedbackHandler={sendFeedbackHandler}
          />
        ))}
      </div>
    );
  };

  const renderIndividualFindingsTab = () => {
    if (!jobMetadata) return null;

    const kycAgentInstance = jobMetadata.kyc;

    if (!kycAgentInstance) return null;

    return <IDDReport endpointUrl={endpointUrl} jobMetadata={jobMetadata} agentKey={agentKey} />;
  };

  const renderAssociatedEntitiesTab = () => {
    if (!jobMetadata) return null;

    const entityAgentInstances = jobMetadata.entity;

    if (!entityAgentInstances) return null;

    return (
      <div className="flex flex-col gap-y-4">
        {Object.values(entityAgentInstances).map((agentInstance, index) => (
          <EntityAgentInstanceSection
            key={`${agentInstance.agent_instance_id}-${index}`}
            agentInstance={agentInstance}
            job={job}
            endpointUrl={endpointUrl}
            isDownloadingReport={isDownloadingReport}
          />
        ))}
      </div>
    );
  };

  const getDocumentName = (docCheck: any) => {
    let docs: ReactNode[] = [];

    switch (docCheck.command_id) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        docs = job.input_payload.self_attested_data.incorporation_documents?.map((doc) => ({
          document: doc,
        }));
        break;
      case BUSINESS_OWNERSHIP_CHECK_ID:
        docs = job?.input_payload.self_attested_data.business_ownership_documents;
        break;
      case PROOF_OF_ADDRESS_CHECK_ID:
      case PROOF_OF_ADDRESS_CLASSIFICATION_CHECK_ID:
        docs = job?.input_payload.self_attested_data.proof_of_address_documents;
        break;
      case KYC_PROOF_OF_ADDRESS_CHECK_ID:
        docs = [
          ...(docCheck.verified_data?.invalid_documents || []),
          ...(docCheck.verified_data?.proof_of_address_documents || []),
          ...(docCheck.verified_data?.valid_documents || []),
        ];
        break;
      case EIN_DOCUMENT_CHECK_ID:
        docs = job?.input_payload.self_attested_data.ein_documents;
        break;
      case SOURCE_OF_FUNDS_CHECK_ID:
      case KYC_SOURCE_OF_FUNDS_CHECK_ID:
        docs = job?.input_payload.self_attested_data.source_of_funds_documents;
        break;
      case BANK_CHECK_VERIFICATION_CHECK_ID:
        docs = job?.input_payload.self_attested_data.bank_check_documents;
        break;
      case CANNABIS_LICENSE_CHECK_ID:
        docs = job?.input_payload.self_attested_data.cannabis_license_documents;
        break;
      default:
        break;
    }

    return (
      <div className="flex flex-col gap-y-1.5">
        {docs?.length > 0 ? (
          docs.map((doc, index) => {
            const document = doc?.document ? doc.document : doc;
            return (
              <button
                key={index}
                onClick={() => handleDocumentValidationClick(docCheck, document)}
                className="text-left w-fit text-brand-purple hover:underline"
              >
                <span>
                  {document?.file_name?.length > 32
                    ? `${document?.file_name?.slice(0, 32)}...${document?.file_name?.slice(-32)}`
                    : document?.file_name}
                </span>
              </button>
            );
          })
        ) : (
          <span className="text-slate-500">No documents provided</span>
        )}
      </div>
    );
  };

  /**
   * Document Checks Start
   */
  const incorporationDocumentCheckResult = kybAgentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === INCORPORATION_DOCUMENT_CHECK_ID,
  );
  const businessOwnershipCheckResult = kybAgentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === BUSINESS_OWNERSHIP_CHECK_ID,
  );
  const sourceOfFundsCheckResult = kybAgentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === SOURCE_OF_FUNDS_CHECK_ID,
  );
  const proofOfAddressCheckResult = kybAgentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === PROOF_OF_ADDRESS_CHECK_ID,
  );
  const cannabisLicenseCheckResult = kybAgentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === CANNABIS_LICENSE_CHECK_ID,
  );
  const einDocumentCheckResult = kybAgentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === EIN_DOCUMENT_CHECK_ID,
  );
  const bankCheckDocumentCheckResult = kybAgentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === BANK_CHECK_VERIFICATION_CHECK_ID,
  );
  const proofOfAddressClassificationCheckResult = kybAgentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === PROOF_OF_ADDRESS_CLASSIFICATION_CHECK_ID,
  );

  const kycDocumentCheckResults = [];

  const kycValues = kycAgentInstance ? Object.values(kycAgentInstance) : [];

  kycValues.forEach((value) => {
    const kycProofOfAddressCheckResult = value.check_results.find(
      (checkResult: { command_id: string }) => checkResult.command_id === KYC_PROOF_OF_ADDRESS_CHECK_ID,
    );
    const kycSourceOfFundsCheckResult = value.check_results.find(
      (checkResult: { command_id: string }) => checkResult.command_id === KYC_SOURCE_OF_FUNDS_CHECK_ID,
    );
    kycDocumentCheckResults.push(...[kycProofOfAddressCheckResult, kycSourceOfFundsCheckResult]);
  });

  const kycProofOfAddressCheckResult = kycAgentInstance
    ? Object.values(kycAgentInstance)[0].check_results.find(
        (checkResult: { command_id: string }) => checkResult.command_id === KYC_PROOF_OF_ADDRESS_CHECK_ID,
      )
    : null;
  const kycSourceOfFundsCheckResult = kycAgentInstance
    ? Object.values(kycAgentInstance)[0].check_results.find(
        (checkResult: { command_id: string }) => checkResult.command_id === KYC_SOURCE_OF_FUNDS_CHECK_ID,
      )
    : null;

  /**
   * Document Checks End
   * */

  const kybDocuments = [
    ...(job?.input_payload?.self_attested_data.incorporation_documents || []),
    ...(job?.input_payload?.self_attested_data.proof_of_address_documents || []),
    ...(job?.input_payload?.self_attested_data.source_of_funds_documents || []),
    ...(job?.input_payload?.self_attested_data.business_ownership_documents || []),
    ...(job?.input_payload?.self_attested_data.ein_documents || []),
    ...(job?.input_payload?.self_attested_data.cannabis_license_documents || []),
    ...(job?.input_payload?.self_attested_data.bank_check_documents || []),
  ];

  const kybDocumentCheckResults = [
    incorporationDocumentCheckResult,
    businessOwnershipCheckResult,
    sourceOfFundsCheckResult,
    proofOfAddressCheckResult,
    cannabisLicenseCheckResult,
    einDocumentCheckResult,
    bankCheckDocumentCheckResult,
    proofOfAddressClassificationCheckResult,
  ]
    .filter((doc) => Boolean(doc?.verified_data) || Boolean(doc?.check_result))
    .map((doc) => ({ ...doc, docType: "kyb" }));

  const documentCheckResults = [
    ...kybDocumentCheckResults,
    ...(kycDocumentCheckResults
      .filter((doc) => Boolean(doc?.verified_data))
      .map((doc) => ({ ...doc, docType: "kyc" })) || []),
  ];

  const renderDocuments = () => {
    return (
      <div className="py-4">
        <div className="mb-4">
          <h2 className="px-4 font-bold flex items-center gap-x-2">
            <PlagiarismRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />
            <span className="text-slate-900">Document Validation</span>
          </h2>
        </div>
        <table className="min-w-full bg-white border-b border-gray-200">
          <thead>
            <tr className="text-xs tracking-wider text-slate-700 bg-slate-50">
              <th className="pl-5 py-3 text-left font-semibold border-b">Document Type</th>
              <th className="px-5 py-3 text-left font-semibold border-b">Entity</th>
              <th className="px-5 py-3 text-left font-semibold border-b">Status</th>
              <th className="px-5 py-3 text-left font-semibold border-b">Pass/Fail</th>
              <th className="pr-5 py-3 text-right font-semibold border-b">Timestamp</th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {documentCheckResults.map((docCheck) => (
              <tr key={`${docCheck.command_id}-${docCheck.agent_instance_id}`}>
                <td className="p-5 border-b">
                  <div className="flex flex-col gap-y-1">
                    <span className="text-sm font-semibold">{docCheck.command_name.replaceAll("Check", "")}</span>
                    <span className="text-slate-500">{getDocumentName(docCheck)}</span>
                  </div>
                </td>
                <td className="p-5 border-b text-sm">
                  {docCheck.docType === "kyb" ? (
                    <div className="flex items-center gap-x-3">
                      <BusinessRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
                      <span>Business</span>
                    </div>
                  ) : (
                    <div className="flex items-center gap-x-3">
                      <PersonRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
                      <span>Individual</span>
                    </div>
                  )}
                </td>
                <td className="p-5 border-b text-sm">
                  <div className="flex items-center gap-x-3">
                    {docCheck.status === "complete" ? (
                      <TaskRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
                    ) : docCheck.status === "running" ? (
                      <TimelapseRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
                    ) : (
                      <HourglassTopRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
                    )}
                    <span>
                      {docCheck.status === "complete"
                        ? "Documents Processed"
                        : docCheck.status === "running"
                          ? "Processing Document"
                          : "Pending"}
                    </span>
                  </div>
                </td>
                <td className="p-5 border-b">
                  {docCheck.passed !== null ? (
                    docCheck.passed ? (
                      <WrappedValue
                        text={
                          <div className="flex items-center gap-x-1">
                            <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
                            Pass
                          </div>
                        }
                        level="success"
                      />
                    ) : (
                      <WrappedValue
                        text={
                          <div className="flex items-center gap-x-1">
                            <CancelRounded sx={{ fontSize: "1rem" }} className="text-red-600" /> Fail
                          </div>
                        }
                        level="failure"
                      />
                    )
                  ) : (
                    <WrappedValue
                      text={
                        <div className="flex items-center gap-x-1">
                          <HourglassTopRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
                          Pending
                        </div>
                      }
                      level="normal"
                    />
                  )}
                </td>
                <td className="text-sm p-5 border-b text-right">
                  {docCheck?.created_at && (
                    <>
                      <span className="text-slate-700 font-semibold">
                        {format(new Date(`${docCheck.created_at}Z`), "MMMM d")}
                      </span>
                      <span className="text-slate-500"> at {format(new Date(`${docCheck.created_at}Z`), "p")}</span>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handleDocumentValidationClick = (docCheck: any, selectedDocument: any) => {
    let values: any[] = [];

    const document = selectedDocument.document ? selectedDocument.document : selectedDocument;

    setSelectedValidationDocument({
      checkId: docCheck.command_id,
      checkName: docCheck.command_name,
      agentInstanceId: docCheck.agent_instance_id,
      documentUrl: `${window.location.protocol}//${endpointUrl}/getDocument?case_id=${job?.input_payload
        .id}&expired_url=${encodeURIComponent(document.url)}`,
      documentTitle: document.file_name,
    });
  };

  const handleCopyToClipboard = () => {
    if (finalAnswer) {
      navigator.clipboard.writeText(finalAnswer?.content?.output?.follow_up);
    }
  };

  const generatePdfReport = async () => {
    // Early return if required data is missing
    if (!agent?.agent_key || !job?.id || !job?.input_payload?.id) {
      console.error("Missing required data for report generation");
      return;
    }

    const isCoinbaseAgent = agent.agent_key.includes("coinbase-");

    try {
      setToastState({ show: true, status: "in progress" });

      if (isCoinbaseAgent) {
        await generateCoinbaseReport();
        setIsDownloadingReport(false);
        return;
      }

      await generateDefaultReport();
      setIsDownloadingReport(false);
    } catch (error) {
      console.error("Error generating PDF report:", error);
      setToastState({ show: true, status: "error" });
      setIsDownloadingReport(false);
    }
  };

  const generateCoinbaseReport = async () => {
    const response = await parchaApi.downloadReport(endpointUrl, agentKey, job.id, job.input_payload.id, true);
    const fileName = `${getJobName().toLowerCase().replace(/ /g, "_")}-report.pdf`;

    await downloadBlob(await response.blob(), fileName);

    setToastState({ show: false, status: "completed" });

    setTimeout(() => {
      setToastState({ show: false, status: "in progress" });
    }, 5000);

    return response;
  };

  const generateDefaultReport = async () => {
    // Default report generation logic here
    const reportElement = reportContainerRef.current;
    if (!reportElement) return;

    setToastState({ show: true, status: "in progress" });

    try {
      const canvas = await html2canvas(reportElement, {
        scale: 1,
        scrollY: 0,
        useCORS: false,
        allowTaint: false,
        ignoreElements: (element) => element.classList.contains("ignore-in-pdf"),
        logging: envTier === "development",
        windowWidth: document.documentElement.scrollWidth,
        windowHeight: reportElement.scrollHeight,
        proxy: `${window.location.protocol}//${endpointUrl}/image-proxy`,
      });

      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const scaleRatio = pageWidth / imgWidth;
      const scaledHeight = imgHeight * scaleRatio;

      let position = 0;

      while (position < scaledHeight) {
        if (position > 0) {
          pdf.addPage();
        }

        pdf.addImage(imgData, "PNG", 0, -position, pageWidth, scaledHeight, undefined, "FAST");

        position += pageHeight;
      }

      const links = reportElement.querySelectorAll("a");
      links.forEach((link) => {
        const rect = link.getBoundingClientRect();
        const elementTop = rect.top - reportElement.getBoundingClientRect().top;

        const linkX = (rect.left / canvas.width) * pageWidth;
        const linkY = (elementTop / canvas.height) * scaledHeight;
        const linkWidth = (rect.width / canvas.width) * pageWidth;
        const linkHeight = (rect.height / canvas.height) * scaledHeight;

        const linkPage = Math.floor(linkY / pageHeight);
        const linkYOnPage = linkY - linkPage * pageHeight;

        pdf.setPage(linkPage + 1);
        pdf.link(linkX, linkYOnPage, linkWidth, linkHeight, { url: link.href });
      });

      pdf.save(`${getJobName().toLowerCase().replace(/ /g, "_")}-report.pdf`);
      setToastState({ show: true, status: "completed" });

      setTimeout(() => {
        setToastState({ show: false, status: "in progress" });
      }, 5000);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setToastState({ show: false, status: "in progress" });
    }
  };

  const downloadBlob = async (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob);

    try {
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
    } finally {
      // Cleanup
      window.URL.revokeObjectURL(url);
    }
  };

  const Toast = () => {
    if (!toastState.show) return null;

    return (
      <div
        className={twJoin(
          "w-1/4 h-[2.5rem] absolute top-0 left-1/2 -translate-x-1/2 z-50 p-4 flex items-center justify-between shadow-md rounded-b-lg",
          toastState.status === "in progress" ? "bg-indigo-100" : "bg-green-100",
        )}
      >
        <div className="flex items-center gap-x-2">
          {toastState.status === "in progress" ? (
            <TimelapseRounded sx={{ fontSize: "1rem" }} className="text-indigo-600" />
          ) : (
            <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
          )}
          <span
            className={twJoin("text-sm", toastState.status === "in progress" ? "text-indigo-700" : "text-green-700")}
          >
            {toastState.status === "in progress" ? "Generating report..." : "Download complete!"}
          </span>
        </div>
        {toastState.status === "completed" && (
          <button onClick={() => setToastState({ show: false, status: "in progress" })}>
            <CloseRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
          </button>
        )}
      </div>
    );
  };

  const handlePopState = useCallback(
    (e: PopStateEvent) => {
      if (selectedValidationDocument) {
        setSelectedValidationDocument(null);
        setSelectedTab("findings");
      }
    },
    [selectedValidationDocument],
  );

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [handlePopState]);

  useEffect(() => {
    if (isTransitioningReportDownload) {
      setIsDownloadingReport(true);
      setIsTransitioningReportDownload(false);
    }
  }, [isTransitioningReportDownload]);

  useLayoutEffect(() => {
    if (!isDownloadingReport) return;

    generatePdfReport();
  }, [isDownloadingReport]);

  const handleDownloadReportClick = async () => {
    if (selectedTab === "findings") {
      setIsDownloadingReport(true);
      return;
    }

    if (agentType === "kyb") {
      setSelectedTab("findings");
    }

    setIsTransitioningReportDownload(true);
  };

  const handleRetryJobClick = () => {
    const input = {
      batch_id: job?.batch_id ?? undefined,
      agent_key: endpoint?.agentKey,
      kyb_schema: agent?.agent_type === "kyb" ? job?.input_payload : undefined,
      kyc_schema: agent?.agent_type === "kyc" ? job?.input_payload : undefined,
      run_in_parallel: true,
    };

    parchaApi.enqueueAgent(endpoint?.endpointUrl, input, agent.agent_type).then((res) => {
      window.location.href = `/jobs/${endpoint?.agentKey}/${res.job_id}`;
    });
  };

  const handleDeleteJobActionClick = () => {
    setIsDeleteJobDialogOpen(true);
  };

  const onTabChangeHandler = (id) => {
    if (id === selectedTab) return;

    setSelectedTab(id);
  };

  const getJobName = () => {
    if (!job?.input_payload?.self_attested_data) return "";

    return (
      job.input_payload?.self_attested_data?.name ||
      job.input_payload?.self_attested_data?.business_name ||
      job.input_payload?.self_attested_data?.registered_business_name ||
      job.input_payload?.self_attested_data?.website?.replace(/https?:\/\/(www\.)?|www\./gi, "") ||
      `${job.input_payload?.self_attested_data?.first_name}${
        job.input_payload?.self_attested_data?.middle_name
          ? ` ${job.input_payload?.self_attested_data?.middle_name} `
          : " "
      }${job.input_payload?.self_attested_data?.last_name}`
    );
  };

  const deleteJob = async () => {
    if (!job) return;
    setIsDeletingJob(true);
    await parchaApi.deleteJob(endpointUrl, job.id);
    setIsDeletingJob(false);
    window.location.href = `/jobs/${agentKey}`;
  };
  return isLoading ? (
    <ParchaLoadingScreen message="Loading your report..." size="large" />
  ) : jobMetadata && job ? (
    job.status === "queued" ? (
      <div className="flex flex-col justify-center items-center w-full h-full text-brand-purple text-sm gap-y-2">
        <HourglassTopRounded sx={{ fontSize: "2rem" }} className="text-slate-500" />
        <div className="text-center">
          <p className="font-bold">This job is queued up</p>
          <p>It will start executing once the current in progress jobs are completed.</p>
        </div>
      </div>
    ) : (
      <div className="relative w-full h-0 min-h-full overflow-auto">
        <Toast />
        <div className="flex items-center justify-between bg-slate-50 p-5">
          <div className="flex flex-col grow gap-y-2 ">
            <div className="flex items-center gap-x-5">
              <div className="font-semibold">{getJobName()}</div>
              {job.recommendation && recommendationComponents[job.recommendation]}
            </div>
            <div className="flex items-center gap-1 md:gap-2 text-xs md:text-sm">
              <div>
                <StatusIndicator status={job.status} />
              </div>
              <div className="capitalize">{job.status}</div>
              {job.status && job.started_at && <div className="hidden md:block">•</div>}
              <div className="hidden md:block">
                {job.started_at && formatDistanceToNowStrict(new Date(`${job.started_at}Z`), { addSuffix: true })}
              </div>
            </div>
          </div>
          <div className="flex gap-1 md:pr-5">
            <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-3 py-2 text-xs md:text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              onClick={() => setIsGenerateFollowUpOpen(true)}
              disabled={!finalAnswer?.content?.output?.follow_up}
            >
              <AutoAwesome sx={{ fontSize: "1.25rem" }} className="-ml-0.5" aria-hidden="true" />
              Generate Follow-up
            </button>
            <Menu as="div" className="text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-1 md:px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <MoreHoriz className="h-3 w-3 md:h-5 md:w-5 text-slate-900" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-3 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="flex flex-col  divide-y divide-solid">
                    <Menu.Item>
                      <div className="px-4 py-3 text-sm text-slate-900 hover:bg-slate-100">
                        <button
                          className="text-left w-full disabled:opacity-60 disabled:cursor-not-allowed"
                          onClick={handleDownloadReportClick}
                          disabled={job.status !== "complete"}
                        >
                          Download Report
                        </button>
                      </div>
                    </Menu.Item>
                    {hasRole("analyst") && (
                      <Menu.Item>
                        <div className="px-4 py-3 text-sm text-slate-900 hover:bg-slate-100">
                          <button
                            disabled={isDeletingJob}
                            className="text-left w-full"
                            onClick={handleDeleteJobActionClick}
                          >
                            Delete Job
                          </button>
                        </div>
                      </Menu.Item>
                    )}
                    {hasRole("analyst") && (
                      <Menu.Item>
                        <div className="px-4 py-3 text-sm text-slate-900 hover:bg-slate-100">
                          <button className="text-left w-full" onClick={handleRetryJobClick}>
                            Retry Job
                          </button>
                        </div>
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <FeedbackContext.Provider
          value={{
            feedbackData: {
              ...latestFeedbackData,
              endpoint,
              data: { ...finalAnswer?.content },
              job_id: jobMetadata.job.id,
            },
            setLatestFeedbackData,
            isLoading: isLoadingFeedbackData,
          }}
        >
          {finalAnswerCheckResult && finalAnswerCheckResult.answer ? (
            <FinalOutput
              level={getFinalOutputLevel()}
              title={getFinalOutputTitle()}
              message={
                <div className="flex flex-col gap-y-2">
                  <h2 className="font-semibold">{getFinalOutputTitle()}</h2>
                  <div>{finalAnswerCheckResult.answer}</div>
                </div>
              }
              feedbackContent={finalAnswerCheckResult}
            />
          ) : null}
          {!["queued", "error"].includes(jobMetadata.job.status) && (
            <FullWidthTabs
              tabs={tabs
                .map((tab) => {
                  if (
                    (((tab.id === "findings" && shouldRenderFindingsTab) || tab.id === "business") &&
                      kybAgentInstanceId !== null) ||
                    (tab.id === "individuals" && shouldRenderIndividualsTab) ||
                    (tab.id === "individual_findings" && shouldRenderIndividualFindingsTab) ||
                    (tab.id === "associated_entities" && shouldRenderAssociatedEntitiesTab) ||
                    (tab.id === "documents" && kybDocuments?.length > 0)
                  ) {
                    return {
                      ...tab,
                      name:
                        tab.id === "individual_findings" && shouldRenderIndividualFindingsTab
                          ? `${jobMetadata.job.input_payload.self_attested_data.first_name} ${jobMetadata.job.input_payload.self_attested_data.last_name} Findings`
                          : tab.name,
                      count:
                        tab.id === "individuals"
                          ? kycAgentInstanceIds?.length
                          : tab.id === "associated_entities"
                            ? entityAgentInstanceIds?.length
                            : 0,
                    };
                  }
                  return null;
                })
                .filter((tab) => Boolean(tab))}
              selectedTab={selectedTab}
              onTabChangeHandler={onTabChangeHandler}
              enableValueSelector={false}
            />
          )}
          <div className="flex flex-col gap-y-3 p-5 " ref={reportContainerRef}>
            {selectedTab === "findings" && (
              <ParchaReport agent={agent} jobMetadata={jobMetadata} disableAnimation={isTransitioningReportDownload} />
            )}
            {selectedTab === "business" || (shouldRenderFindingsTab && isDownloadingReport)
              ? renderBusinessTab()
              : null}
            {selectedTab === "individuals" || (shouldRenderIndividualsTab && isDownloadingReport)
              ? renderIndividualsTab()
              : null}
            {selectedTab === "individual_findings" || (shouldRenderIndividualFindingsTab && isDownloadingReport)
              ? renderIndividualFindingsTab()
              : null}
            {selectedTab === "associated_entities" || (shouldRenderAssociatedEntitiesTab && isDownloadingReport)
              ? renderAssociatedEntitiesTab()
              : null}
            {selectedTab === "documents" && renderDocuments()}
          </div>
        </FeedbackContext.Provider>

        <Transition.Root show={isGenerateFollowUpOpen} as={Fragment}>
          <Dialog as="div" initialFocus={dialogDivRef} className="relative z-20" onClose={setIsGenerateFollowUpOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="h-[20rem] min-h-[20rem] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                        onClick={() => setIsGenerateFollowUpOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <Close className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start h-full">
                      <div className="mt-3 text-center w-full sm:mt-0 sm:text-left" ref={dialogDivRef}>
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-slate-900">
                          Follow-up Message
                        </Dialog.Title>
                        <div className="flex flex-col gap-2 py-4 h-full justify-between ">
                          <p className="p-4 w-full leading-4 text-xs text-slate-700 overflow-x-hidden overflow-y-auto h-[12rem] border-solid border border-slate-300 rounded-md">
                            <ReactMarkdown>{finalAnswer?.content.output.follow_up}</ReactMarkdown>
                          </p>
                          <div>
                            <button
                              tabIndex={1}
                              className="w-6 h-6 p-1 text-center border-solid border border-slate-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                              onClick={handleCopyToClipboard}
                            >
                              <span className="sr-only">Copy to clipboard</span>
                              <CopyAllRounded
                                sx={{ fontSize: "1rem", width: "1rem", height: "1rem" }}
                                className="text-slate-900"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={isDeleteJobDialogOpen} as={Fragment}>
          <Dialog as="div" className="relative z-20" onClose={setIsDeleteJobDialogOpen} initialFocus={deleteJobDivRef}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-slate-900">
                      Are you sure you want to delete this job
                    </Dialog.Title>
                    <div className="flex flex-col gap-3 mt-3 text-sm" ref={deleteJobDivRef}>
                      <p>Once you delete the job you will no longer have access to it.</p>
                      <div className="flex justify-end gap-2">
                        <button
                          className="border-solid border border-slate-300 rounded-sm h-6 px-2 py-1 focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                          onClick={() => setIsDeleteJobDialogOpen(false)}
                        >
                          Nevermind
                        </button>
                        <button
                          className="bg-brand-purple rounded-sm px-2 py-1 text-white focus:outline-none focus:ring-2 focus:ring-brand-purple focus:ring-offset-2"
                          onClick={deleteJob}
                        >
                          Yes, delete it
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {selectedValidationDocument && (
          <Transition.Root show={Boolean(selectedValidationDocument)} as={Fragment} appear>
            <Dialog as="div" className="relative z-20" onClose={() => setSelectedValidationDocument(null)}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-white bg-opacity-100 transition-opacity" />
              </Transition.Child>
              <div className="fixed inset-0 z-20 w-full h-full overflow-hidden">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full h-full transition-all">
                    <div className="flex items-center justify-between py-2 px-4 border-b border-solid border-slate-200">
                      <div className="flex items-center justify-between gap-x-2 text-slate-900">
                        <PlagiarismRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                        <h2 className="font-semibold">Document Validation</h2>
                        <div className="flex items-center gap-x-2 ml-5">
                          {agent?.agentType === "kyb" ? (
                            <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                          ) : (
                            <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                          )}
                        </div>
                        <span>{getJobName()}</span>
                      </div>
                      <button
                        className="ml-auto inline-flex items-center border border-solid border-slate-200 px-2 py-1 rounded-md text-sm"
                        onClick={() => setSelectedValidationDocument(null)}
                      >
                        <CloseRounded sx={{ fontSize: "1rem" }} className="mr-1" />
                        <span>Close</span>
                      </button>
                    </div>
                    <DocumentValidation
                      agentInstanceId={selectedValidationDocument.agentInstanceId}
                      jobId={job.id}
                      endpointUrl={endpointUrl}
                      documentUrl={selectedValidationDocument.documentUrl}
                      documentName={selectedValidationDocument.documentTitle}
                      agentType={agent.agentType}
                      checkId={selectedValidationDocument.checkId}
                      checkName={selectedValidationDocument.checkName?.replace("Check", "")}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        )}

        <Fab
          color="primary"
          aria-label="View Job Details"
          style={{
            position: "fixed",
            bottom: "2rem",
            right: "2rem",
            zIndex: 20,
          }}
          onClick={() => setIsJobDetailsOpen(true)}
        >
          <Info />
        </Fab>

        <Drawer
          anchor="right"
          open={isJobDetailsOpen}
          onClose={() => setIsJobDetailsOpen(false)}
          PaperProps={{
            style: {
              width: "25vw",
              minWidth: "400px",
            },
          }}
        >
          <div className="px-2 h-full overflow-y-auto">
            <NewAgentChatSSE
              endpoint={endpoint}
              isParchaUser={hasRole("parchaUser")}
              selectedTabValue={infoTabValue}
              setSelectedTabValue={setInfoTabValue}
              jobMetadata={jobMetadata}
              agent={agent ?? null}
              caseId={job.input_payload.case_id}
              endpointUrl={endpointUrl}
            />
          </div>
        </Drawer>
      </div>
    )
  ) : null;
};

const KYCAgentInstanceSection = ({
  agentInstance,
  job,
  endpointUrl,
  isDownloadingReport,
  handleDocumentValidationClick,
  jobFeedbackData,
  sendFeedbackHandler,
}: {
  agentInstance: any;
  job: any;
  endpointUrl: string;
  isDownloadingReport: boolean;
  handleDocumentValidationClick: (agentInstanceId: string, checkId: string) => void;
  jobFeedbackData: any;
  sendFeedbackHandler: (feedbackData: any) => void;
}) => {
  if (!agentInstance) {
    return null;
  }

  const [isExpanded, setIsExpanded] = useState(true);

  const sectionTitle =
    agentInstance?.check_results.length > 0
      ? `${agentInstance.check_results[0].first_name}${
          agentInstance.check_results[0].middle_name ? ` ${agentInstance.check_results[0].middle_name} ` : " "
        }${agentInstance.check_results[0].last_name}`
      : "";

  return (
    <div className="flex flex-col gap-y-5">
      <button onClick={() => setIsExpanded(!isExpanded)} className="inline-flex items-center  gap-2">
        {isExpanded || isDownloadingReport ? (
          <ExpandLessIcon sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
        ) : (
          <ExpandMoreIcon sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
        )}
        <span className="font-semibold">{sectionTitle}</span>
      </button>
      {(isExpanded || isDownloadingReport) && (
        <>
          {agentInstance?.check_results
            .filter((cr) => !["run_check_finish_plan", "finish_plan"].includes(cr.command_id))
            .map((checkResult) => {
              return (
                <CheckContainer
                  key={`${job.id}-${checkResult.command_instance_id}-${checkResult.agent_instance_id}`}
                  agentRun={job}
                  auditLogVisible={true}
                  commandResult={checkResult}
                  title={checkResult.command_name}
                  auditLogMessages={checkResult.status_messages.filter((message) =>
                    ["api_results", "command_results", "thought"].includes(message.event),
                  )}
                  messages={checkResult.status_messages}
                  sendFeedbackHandler={sendFeedbackHandler}
                  feedbackData={jobFeedbackData}
                  caseId={job.input_payload.case_id}
                  endpointUrl={endpointUrl}
                  handleDocumentValidationClick={handleDocumentValidationClick}
                />
              );
            })}
        </>
      )}
    </div>
  );
};

const EntityAgentInstanceSection = ({
  agentInstance,
  job,
  endpointUrl,
  isDownloadingReport,
}: {
  agentInstance: any;
  job: any;
  endpointUrl: string;
  isDownloadingReport: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const sectionTitle = agentInstance?.check_results[0]?.entity_business_name;

  return (
    <div className="flex flex-col gap-y-4">
      <button onClick={() => setIsExpanded(!isExpanded)} className="inline-flex items-center gap-2">
        {isExpanded ? (
          <ExpandLessIcon sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
        ) : (
          <ExpandMoreIcon sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
        )}
        <span className="font-semibold">{sectionTitle}</span>
      </button>
      {isExpanded && (
        <div key={`${agentInstance.agent_instance_id}`} className="px-6">
          <AssociatedEntityFindings
            job={{
              input_payload: {
                self_attested_data: {
                  business_name: sectionTitle,
                  website: "",
                },
              },
            }}
            checkResults={agentInstance.check_results}
            sources={agentInstance.sources}
            onTryAgainClick={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default NewJobView;
