import { Worker, Viewer } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { pageThumbnailPlugin } from "@/utils/pageThumbnailPlugin";

const PDFThumbnail = ({
  documentURL,
  documentName,
  onDocumentNameClick,
}: {
  documentURL: string;
  documentName: string;
  onDocumentNameClick: () => void;
}) => {
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => 0} />,
  });

  const truncateMiddle = (str: string, maxLength: number) => {
    if (str.length <= maxLength) return str;
    const midPoint = Math.floor(maxLength / 2);
    return `${str.slice(0, midPoint)}...${str.slice(str.length - midPoint + 1)}`;
  };

  return (
    <div className="flex flex-col gap-y-2 h-1/2">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer fileUrl={documentURL} plugins={[thumbnailPluginInstance, pageThumbnailPluginInstance]} />
      </Worker>
      <button
        onClick={onDocumentNameClick}
        className="w-full text-center text-brand-purple hover:underline"
        title={documentName}
      >
        {truncateMiddle(documentName, 16)}
      </button>
    </div>
  );
};

export default PDFThumbnail;
