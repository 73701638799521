import { useState } from "react";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";
import { AddressType } from "@/types";

interface CannabisLicenseFormProps {
  formData: {
    business_name: string | null;
    description: string | null;
    cannabis_license_number: string | null;
    address_of_operation: AddressType | null;
  };
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  formErrors: string[];
}

const CannabisLicenseForm: React.FC<CannabisLicenseFormProps> = ({ formData, setFormData, formErrors }) => {
  const [addressOfOperationAutocomplete, setAddressOfOperationAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [addressOfOperation, setAddressOfOperation] = useState<string>("");
  const libraries: "places"[] = ["places"];

  const onAddressOfOperationLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAddressOfOperationAutocomplete(autocomplete);
  };

  const onPlaceSelectionChange = () => {
    if (addressOfOperationAutocomplete) {
      const place = addressOfOperationAutocomplete.getPlace();
      const addressComponents: AddressType = {
        street_1: null,
        street_2: null,
        city: null,
        country_code: null,
        postal_code: null,
        state: null,
      };
      // Loop through the address components of the selected place
      place.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          addressComponents.street_1 = `${component.long_name}${
            addressComponents.street_1 ? ` ${addressComponents.street_1}` : ""
          }`;
        }
        if (types.includes("route")) {
          addressComponents.street_1 = `${addressComponents.street_1 || ""} ${component.long_name}`;
        }
        if (types.includes("subpremise")) {
          addressComponents.street_2 = component.long_name;
        }
        if (types.includes("locality")) {
          addressComponents.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          addressComponents.state = component.short_name;
        }
        if (types.includes("country")) {
          addressComponents.country_code = component.short_name;
        }
        if (types.includes("postal_code")) {
          addressComponents.postal_code = component.long_name;
        }
      });

      setFormData({ ...formData, address_of_operation: addressComponents });
      setAddressOfOperation(
        `${addressComponents.street_1 || ""}${addressComponents.street_2 ? ` ${addressComponents.street_2}` : ""}${
          addressComponents.city ? ` ${addressComponents.city}` : ""
        }${addressComponents.state ? `, ${addressComponents.state}` : ""}${
          addressComponents.postal_code ? ` ${addressComponents.postal_code}` : ""
        }`.trim(),
      );
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <LoadScript googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY} libraries={libraries}>
      <form>
        <div className="grid grid-cols-4 gap-y-4 gap-x-3">
          <InputWithOverlappingLabel
            required
            fieldName="business_name"
            label="Business Name"
            id="business_name"
            name="business_name"
            onChangeHandler={(e) => setFormData({ ...formData, business_name: e.target.value })}
            className="col-span-3"
            value={formData.business_name || ""}
          />
          <InputWithOverlappingLabel
            required
            fieldName="cannabis_license_number"
            label="License Number"
            id="cannabis_license_number"
            name="cannabis_license_number"
            className="grow"
            onChangeHandler={(e) => setFormData({ ...formData, cannabis_license_number: e.target.value })}
            value={formData.cannabis_license_number || ""}
          />
          <div className="relative col-span-full">
            <label
              htmlFor="description"
              className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-600 w-auto truncate"
            >
              Business Description
            </label>
            <textarea
              rows={4}
              name="description"
              id="description"
              placeholder="Give a brief explanation of what the business does"
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              className="text-xs box-border block w-full rounded-md py-3 text-slate-900 ring-1 ring-inset ring-slate-200 border border-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple"
              value={formData.description || ""}
            />
          </div>
          <div className="col-span-full">
            <Autocomplete onLoad={onAddressOfOperationLoad} onPlaceChanged={() => onPlaceSelectionChange()}>
              <InputWithOverlappingLabel
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                value={addressOfOperation}
                onChangeHandler={(e) => setAddressOfOperation(e.target.value)}
                label="Address"
                id="address_of_operation"
                name="address_of_operation"
                fieldName="address_of_operation"
                className="col-span-4"
              />
            </Autocomplete>
          </div>
        </div>
        {formErrors.length > 0 && (
          <div className="text-red-600 text-sm mt-4">
            <span className="font-semibold">Please correct the following errors:</span>
            <ul className="list-disc list-outside ml-6">
              {formErrors.map((error, index) => (
                <li className="list-item" key={index}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        )}
      </form>
    </LoadScript>
  );
};

export default CannabisLicenseForm;
