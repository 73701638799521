//@ts-nocheck
import { useCallback, useState, useEffect } from "react";
import { twJoin, twMerge } from "tailwind-merge";
import MiniStrengthIndicator from "./MiniStrengthIndicator";
import PerpetratorTable from "./PerpetratorTable";
import FeedbackButtons from "./FeedbackButtons";
import FeedbackStatus from "./FeedbackStatus";
import FeedbackInput from "./FeedbackInput";
import { Feedback, Hit } from "@/types";
import defaultProfilePhoto from "../../assets/default_people_profile.png";
import defaultHouseLogo from "../../assets/default_house.png";
import emailSourcePhoto from "../../assets/mark_email_read.png";
import searchSourcePhoto from "../../assets/screen_search_desktop.png";
import BuildingIcon from "../../assets/building.png";
import {
  CircleOutlined,
  CircleRounded,
  ContentCopyRounded,
  ContrastRounded,
  DoNotDisturbOnOutlined,
} from "@mui/icons-material";
import ProfileTable from "./ProfileTable";
import { Tooltip, styled } from "@mui/material";

const SourceOfWealthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`    
    background-color: rgba(15, 23, 42, 0.8);   
    padding: 8px 16px;

    & .MuiTooltip-arrow {
      color: rgba(15, 23, 42, 0.8);
    }
`);

type RealEstateCardProps = {
  hit: Hit;
  input: unknown;
};

const RealEstateCard = ({ hit, input }: RealEstateCardProps) => {
  const [showToast, setShowToast] = useState(false);

  const isPartialMatch = hit.profile_review.match_rating.match === "partial_match";

  const rowId = hit.reference_id ?? hit.id;

  const handleCopyClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const idToCopy = rowId?.split("_").at(-1) ?? "";
      navigator.clipboard.writeText(idToCopy);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    },
    [rowId],
  );

  const firstNameMatch = hit.profile_review.first_name_match;
  const middleNameMatch = hit.profile_review.middle_name_match;
  const lastNameMatch = hit.profile_review.last_name_match;
  const ageMatch = hit.profile_review.age_match;
  const countryMatch = hit.profile_review.country_match;
  const locationMatch = hit.profile_review.location_match;
  const profileForename = hit.profile_review.forename;
  const profileMiddleName = hit.profile_review.middle_name;
  const profileSurname = hit.profile_review.surname;

  function formatNumberToCurrency(num: number | null | undefined): string {
    // Return early if num is null/undefined
    if (num === null || num === undefined) {
      return "$0";
    }

    // Convert to number if it's a string
    const value = Number(num);

    // Check if conversion resulted in a valid number
    if (isNaN(value)) {
      return "$0";
    }

    if (value >= 1e9) {
      return `$${(value / 1e9).toFixed(value % 1e9 === 0 ? 0 : 2)}B`; // Billion
    } else if (value >= 1e6) {
      return `$${(value / 1e6).toFixed(value % 1e6 === 0 ? 0 : 2)}M`; // Million
    } else if (value >= 1e3) {
      return `$${(value / 1e3).toFixed(value % 1e3 === 0 ? 0 : 2)}K`; // Thousand
    } else {
      return `$${value.toFixed(value % 1 === 0 ? 0 : 2)}`; // Less than thousand
    }
  }

  const formatDate = (dateString: string | null): string => {
    if (!dateString) return "Unknown";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
  };

  return (
    <div className={twJoin("flex flex-col overflow-hidden rounded-lg")}>
      {/* Card Header */}
      <div className={twMerge("flex bg-slate-50 justify-between py-3 pr-3 pl-5 gap-x-5 gap-y-4 group w-full")}>
        <div className="flex items-center justify-between w-full">
          <div className="gap-y-1 w-auto">
            <div className="flex items-start gap-x-5">
              {hit.real_estate_property &&
                (hit.real_estate_property.zillow_image_url || defaultHouseLogo) &&
                (hit.real_estate_property.zillow_url ? (
                  <a
                    href={hit.real_estate_property.zillow_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      src={hit.real_estate_property.zillow_image_url || defaultHouseLogo}
                      alt={`Property image`}
                      className="w-13 h-10 shrink-0"
                      style={{ borderRadius: "4px" }}
                    />
                  </a>
                ) : (
                  <img
                    src={hit.real_estate_property.zillow_image_url || defaultHouseLogo}
                    alt={`Property image`}
                    className="w-13 h-10 shrink-0"
                    style={{ borderRadius: "4px" }}
                  />
                ))}
              <div className="flex flex-col gap-y-1">
                <div className="text-sm font-semibold text-slate-700 inline-flex items-center gap-x-3">
                  {hit.real_estate_property.address_full}
                  {hit.real_estate_property.zillow_url && (
                    <a
                      href={hit.real_estate_property.zillow_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="w-3.5 h-3.5 rounded-full bg-slate-200 flex items-center justify-center text-[8px] text-slate-500 hover:bg-slate-300"
                      onClick={(e) => e.stopPropagation()}
                    >
                      1
                    </a>
                  )}
                </div>
                <div className="text-xs text-slate-500">
                  Purchased: {formatDate(hit.real_estate_property.zillow_last_sold_date)}
                </div>
              </div>
            </div>
          </div>
          <div className="text-right flex justify-center h-full w-auto">
            <div className="flex items-center mr-5">
              <SourceOfWealthTooltip
                className="px-4 py-2 rounded text-white text-[11px] leading-[14px]"
                title={"Match found with property owner lookup"}
                placement="top"
                arrow
              >
                <img
                  src={searchSourcePhoto}
                  alt={"Property owner search source"}
                  className="w-5 h-5 mr-2"
                />
              </SourceOfWealthTooltip>
              <MiniStrengthIndicator matchRating={hit.profile_review.match_rating.match} greenColor={true} />
            </div>
            <div className="flex flex-col justify-center h-full gap-y-1">
              {hit.real_estate_property && (
                <div className="flex items-center gap-x-2 justify-end">
                  <span className="text-xs text-slate-700 font-semibold">Estimated Value:</span>
                  <span
                    className={`text-xs font-semibold ${
                      hit.real_estate_property?.zillow_estimated_value !== null &&
                      hit.real_estate_property?.zillow_estimated_value !== undefined
                        ? "text-green-700"
                        : "text-slate-700"
                    }`}
                  >
                    {hit.real_estate_property?.zillow_estimated_value !== null &&
                    hit.real_estate_property?.zillow_estimated_value !== undefined
                      ? formatNumberToCurrency(hit.real_estate_property.zillow_estimated_value)
                      : "Unknown"}
                  </span>
                </div>
              )}
              { hit.real_estate_property && (
                <div className="flex items-center gap-x-2 justify-end">
                  <span className="text-xs text-slate-700 font-semibold">Lien Balance:</span>
                  <span className={`text-xs font-semibold ${hit.real_estate_property?.total_lien_balance !== null && hit.real_estate_property?.total_lien_balance !== undefined ? 'text-green-700' : 'text-slate-700'}`}>
                    {hit.real_estate_property?.total_lien_balance !== null && hit.real_estate_property?.total_lien_balance !== undefined 
                      ? formatNumberToCurrency(hit.real_estate_property.total_lien_balance)
                      : 'Unknown'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Feedback Controls */}
        <div className="flex flex-col w-fit items-end gap-2 shrink-0">
          <div className="flex items-center gap-x-5 w-fit text-xs flex-1 shrink-0">
            {hit.source_of_wealth_estimation &&
              hit.source_of_wealth_estimation.current_annual_salary_low != null &&
              hit.source_of_wealth_estimation.current_annual_salary_high != null && (
                <div className="text-xs text-slate-700 font-semibold">
                  Annual Salary:{" "}
                  <span className="text-xs text-green-700 font-semibold">
                    {formatNumberToCurrency(hit.source_of_wealth_estimation.current_annual_salary_low)} -{" "}
                    {formatNumberToCurrency(hit.source_of_wealth_estimation.current_annual_salary_high)}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateCard;
