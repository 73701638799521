import { CalendarTodayRounded, CircleRounded, CircleOutlined, ContrastRounded, DoNotDisturbOnOutlined, LocationOnRounded, PersonSearchRounded, EmailRounded } from "@mui/icons-material";
import { twJoin } from "tailwind-merge";

const MatchPill = ({ matchRating }: { matchRating: string }) => {
    return (
      <div
        className={twJoin(
          "w-fit text-xs px-2 py-1 flex gap-x-1 rounded-full truncate items-center font-semibold",
          matchRating === "strong_match"
            ? "text-green-700 bg-green-100"
            : matchRating === "partial_match"
              ? "text-green-500 bg-green-100"
              : matchRating === "mismatch"
                ? "text-yellow-800 bg-yellow-100"
                : "text-slate-700 bg-slate-100",
        )}
      >
        {matchRating === "strong_match" ? (
          <CircleRounded sx={{ fontSize: "0.75rem" }} />
        ) : matchRating === "partial_match" ? (
          <ContrastRounded sx={{ fontSize: "0.75rem" }} />
        ) : matchRating === "mismatch" ? (
          <DoNotDisturbOnOutlined sx={{ fontSize: "0.75rem" }} />
        ) : (
          <CircleOutlined sx={{ fontSize: "0.75rem" }} />
        )}
        {matchRating === "strong_match"
          ? "Exact match"
          : matchRating === "partial_match"
            ? "Partial match"
            : matchRating === "mismatch"
              ? "Mismatch"
              : "Missing"}
      </div>
    );
  };    

const ProfileTable = ({ mediaHit, input, hitTableLabel }: { mediaHit: any; input: any; hitTableLabel: string}) => {
  const mediaHitObj = mediaHit.profile_review || mediaHit;
  const locationMatch = mediaHitObj.location_match;

  const capitalizeFirstLetter = (str?: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const firstNameMatch = {
    ...mediaHitObj.first_name_match,
    name: capitalizeFirstLetter(mediaHitObj.first_name_match?.name)
  };
  const middleNameMatch = {
    ...mediaHitObj.middle_name_match,
    name: capitalizeFirstLetter(mediaHitObj.middle_name_match?.name)
  };
  const lastNameMatch = {
    ...mediaHitObj.last_name_match,
    name: capitalizeFirstLetter(mediaHitObj.last_name_match?.name)
  };
  const ageMatch = mediaHitObj.age_match;
  const profileForename = mediaHit.forename;
  const profileMiddleName = mediaHit.middle_name;
  const profileSurname = mediaHit.surname;

  return (
    <table className="w-0 min-w-full table-auto leading-normal text-xs border-b border-slate-300">
      <thead>
        <tr className="text-left bg-slate-50">
          <th className="py-2 px-1.5 pl-5"></th>
          <th className="py-2 px-1.5 text-slate-500">Customer Information</th>
          <th className="py-2 px-1.5">{hitTableLabel}</th>
          <th className="py-2 px-1.5"></th>
        </tr>
      </thead>
      <tbody className="w-full border-separate border-spacing-2 divide-y divide-slate-200">
        <tr className="text-left">
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <PersonSearchRounded sx={{ fontSize: "1rem" }} />
              <span>First Name</span>
            </div>
          </td>
          <td className="text-slate-900 truncate px-1.5 py-2">
            <span>{input.first_name}</span>
          </td>
          <td
            className={twJoin(
              "truncate px-1.5 py-2",
              firstNameMatch?.exact_match
                ? "text-green-700 font-semibold"
                : firstNameMatch?.partial_match
                  ? "text-green-500"
                  : firstNameMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-600 italic",
            )}
          >
            <span className={twJoin(firstNameMatch?.exact_match ? "font-semibold" : "")}>
              {firstNameMatch?.name ?? profileForename}
            </span>
          </td>
          <td className="px-1.5 py-2 text-right">
            <div className="inline-flex">
              <>
                {firstNameMatch?.exact_match ? (
                  <MatchPill matchRating="strong_match" />
                ) : firstNameMatch?.partial_match ? (
                  <MatchPill matchRating="partial_match" />
                ) : firstNameMatch?.mismatch ? (
                  <MatchPill matchRating="mismatch" />
                ) : (
                  <MatchPill matchRating="no_match" />
                )}
              </>
            </div>
          </td>
        </tr>
        <tr className="text-left">
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <PersonSearchRounded sx={{ fontSize: "1rem" }} />
              <span>Middle Name</span>
            </div>
          </td>
          <td
            className={twJoin("text-slate-900 truncate px-1.5 py-2", !input.middle_name ? "italic text-slate-400" : "")}
          >
            <span>{input.middle_name || "-"}</span>
          </td>
          <td
            className={twJoin(
              "truncate px-1.5 py-2",
              middleNameMatch?.exact_match
                ? "text-green-700 font-semibold"
                : middleNameMatch?.partial_match
                  ? "text-green-500"
                  : middleNameMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-400 italic",
            )}
          >
            <span className={middleNameMatch?.exact_match ? "font-semibold" : ""}>
              {middleNameMatch?.name ? middleNameMatch.name : profileMiddleName || "-"}
            </span>
          </td>
          <td className="px-1.5 py-2 text-right">
            <div className="inline-flex">
              <>
                {middleNameMatch?.exact_match ? (
                  <MatchPill matchRating="strong_match" />
                ) : middleNameMatch?.partial_match ? (
                  <MatchPill matchRating="partial_match" />
                ) : middleNameMatch?.mismatch ? (
                  <MatchPill matchRating="mismatch" />
                ) : (
                  <MatchPill matchRating="no_match" />
                )}
              </>
            </div>
          </td>
        </tr>
        <tr className="text-left">
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <PersonSearchRounded sx={{ fontSize: "1rem" }} />
              <span>Last Name</span>
            </div>
          </td>
          <td
            className={twJoin("text-slate-900 truncate px-1.5 py-2", !input.last_name ? "italic text-slate-400" : "")}
          >
            <span>{input.last_name || "-"}</span>
          </td>
          <td
            className={twJoin(
              "truncate px-1.5 py-2",
              lastNameMatch?.exact_match
                ? "text-green-700 font-semibold"
                : lastNameMatch?.partial_match
                  ? "text-green-500"
                  : lastNameMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-600 italic",
            )}
          >
            <span className={twJoin(lastNameMatch?.exact_match ? "font-semibold" : "")}>
              {lastNameMatch?.name ?? profileSurname}
            </span>
          </td>
          <td className="py-2 px-1.5 text-right">
            <div className="inline-flex">
              <>
                {lastNameMatch?.exact_match ? (
                  <MatchPill matchRating="strong_match" />
                ) : lastNameMatch?.partial_match ? (
                  <MatchPill matchRating="partial_match" />
                ) : lastNameMatch?.mismatch ? (
                  <MatchPill matchRating="mismatch" />
                ) : (
                  <MatchPill matchRating="no_match" />
                )}
              </>
            </div>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <CalendarTodayRounded sx={{ fontSize: "1rem" }} />
              <span>Age Today</span>
            </div>
          </td>
          <td
            className={twJoin(
              "text-slate-900 truncate px-1.5 py-2",
              !input.individual_age ? "italic text-slate-400" : "",
            )}
          >
            <span>{input.individual_age ? `${input.individual_age} years old` : "-"}</span>
          </td>
          <td
            className={twJoin(
              "truncate px-1.5 py-2",
              ageMatch?.exact_match
                ? "text-green-700 font-semibold"
                : ageMatch?.partial_match
                  ? "text-green-500"
                  : ageMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-400 italic",
            )}
          >
            {mediaHit.age_low && mediaHit.age_high ? `${mediaHit.age_low} - ${mediaHit.age_high} years old` : "Not Found"}
          </td>

          <td className="px-1.5 py-2 text-right">
            <div className="inline-flex">
              <MatchPill
                matchRating={
                  ageMatch?.exact_match
                    ? "strong_match"
                    : ageMatch?.partial_match
                      ? "partial_match"
                      : ageMatch?.mismatch
                        ? "mismatch"
                        : "no_match"
                }
              />
            </div>
          </td>
        </tr>
        <tr>
          <td className="whitespace-nowrap px-1.5 py-2 pl-5">
            <div className="flex gap-x-2 items-center">
              <LocationOnRounded sx={{ fontSize: "1rem" }} />
              <span>Location</span>
            </div>
          </td>
          <td className="text-slate-900 truncate px-1.5 py-2">
            <span>
              {[
                input.address?.city,
                input.address?.state,
                input.address?.country_code
              ]
                .filter(Boolean)
                .join(", ") || "Not Found"}
            </span>
          </td>
          <td
            className={twJoin(
              "text-wrap px-1.5 py-2",
              locationMatch?.exact_match
                ? "text-green-700 font-semibold"
                : locationMatch?.partial_match
                  ? "text-green-500 font-semibold"
                  : locationMatch?.mismatch
                    ? "text-yellow-800"
                    : "text-slate-400 italic",
            )}  
          >
            {[
              locationMatch?.matched_city,
              locationMatch?.matched_state,
              locationMatch?.matched_country
            ]
              .filter(Boolean)
              .join(", ") || "Not Found"}
          </td>
          <td className="py-2 px-1.5 text-right ">
            <div className="inline-flex">
              <MatchPill
                matchRating={
                  locationMatch?.exact_match
                    ? "strong_match"
                    : locationMatch?.partial_match
                      ? "partial_match"
                      : locationMatch?.mismatch
                        ? "mismatch"
                        : "no_match"
                }
              />
            </div>
          </td>
        </tr>
        {mediaHit.linkedin_source === "pipl" && (
          <tr>
            <td className="whitespace-nowrap px-1.5 py-2 pl-5">
              <div className="flex gap-x-2 items-center">
                <EmailRounded sx={{ fontSize: "1rem" }} />
                <span>Email</span>
              </div>
            </td>
            <td className="text-slate-900 truncate px-1.5 py-2">
              <span>
                {input.email || "Not Found"}
              </span>
            </td>
            <td
              className={twJoin(
                "text-wrap px-1.5 py-2 text-green-700 font-semibold",
              )}  
            >
              {input.email || "Not Found"}
            </td>
            <td className="py-2 px-1.5 text-right ">
              <div className="inline-flex">
                <MatchPill
                  matchRating={
                    "strong_match"
                  }
                />
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ProfileTable;
